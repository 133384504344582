// Function to trigger a change-event
window.callChange = function(obj_caller){
    if(document.createEventObject) {
        obj_caller.fireEvent("onchange");
    } else {
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent("change", false, true);
        obj_caller.dispatchEvent(evt);
    }
};

var selectContainer, selectContainerIndex , selectOptionsIndex, selectElement, fakeSelectDiv, fakeOptionsContainerDiv, fakeOptionsItemDiv;

var myCustomSelect = {};

$(function() {
    var init = function(){
        /* Look for any elements with the class "custom-select": */
        selectContainer = document.getElementsByClassName("afs-custom-select-container");
        for (selectContainerIndex = 0; selectContainerIndex < selectContainer.length; selectContainerIndex++) {
            selectElement = selectContainer[selectContainerIndex].getElementsByTagName("select")[0];
            /* For each element, create a new DIV that will act as the selected item: */
            fakeSelectDiv = document.createElement("DIV");

            if(selectElement.disabled) {
                // Set 'disabled' class if select is disabled.
                fakeSelectDiv.setAttribute("class", "select-selected disabled");
            } else {
                fakeSelectDiv.setAttribute("class", "select-selected");
            }
            fakeSelectDiv.innerHTML = selectElement.options[selectElement.selectedIndex].innerHTML;

            selectContainer[selectContainerIndex].appendChild(fakeSelectDiv);
            /* For each element, create a new DIV that will contain the option list: */
            fakeOptionsContainerDiv = document.createElement("DIV");
            fakeOptionsContainerDiv.setAttribute("class", "select-items select-hide");

            for (selectOptionsIndex = 1; selectOptionsIndex < selectElement.length; selectOptionsIndex++) {
                /* For each option in the original select element,
                create a new DIV that will act as an option item: */
                fakeOptionsItemDiv = document.createElement("DIV");
                fakeOptionsItemDiv.innerHTML = selectElement.options[selectOptionsIndex].innerHTML;
                // Only add click-listener, if not disabled
                if(selectElement.options[selectOptionsIndex].disabled===false) {
                    fakeOptionsItemDiv.addEventListener("click", function(e) {
                        /* When an item is clicked, update the original select box,
                        and the selected item: */
                        var selectedItems, currentSelectElementIndex, selectedItemsIndex, currentSelectElement, currentFakeSelectDiv;
                        currentSelectElement = this.parentNode.parentNode.getElementsByTagName("select")[0];
                        currentFakeSelectDiv = this.parentNode.previousSibling;
                        for (currentSelectElementIndex = 0; currentSelectElementIndex < currentSelectElement.length; currentSelectElementIndex++) {

                            if (currentSelectElement.options[currentSelectElementIndex].innerHTML === this.innerHTML) {
                                currentSelectElement.selectedIndex = currentSelectElementIndex;
                                // Trigger a change-event to make the validation happen
                                callChange(currentSelectElement);
                                /**
                                 * This is for the "Contact Form Events Add-On" only.
                                 * Especially for "arrange a meeting" form
                                 */
                                var $timeSelect = $('#time');
                                if(currentSelectElement.id==="date" && $timeSelect.is(':disabled')) {
                                    var $time = $('#time + div');
                                    $timeSelect.removeAttr('disabled');
                                    $time.removeClass('disabled');
                                    $time.click(clickHandlerFunction)
                                }
                                if(!this.classList.contains("disabled")) {
                                    currentFakeSelectDiv.innerHTML = this.innerHTML;
                                    selectedItems = this.parentNode.getElementsByClassName("same-as-selected");
                                    for (selectedItemsIndex = 0; selectedItemsIndex < selectedItems.length; selectedItemsIndex++) {
                                        selectedItems[selectedItemsIndex].removeAttribute("class");
                                    }
                                    this.setAttribute("class", "same-as-selected");
                                }
                                break;
                            }
                        }
                        currentFakeSelectDiv.click();
                    });

                } else {
                    fakeOptionsItemDiv.setAttribute("class", "disabled");
                }
                fakeOptionsContainerDiv.appendChild(fakeOptionsItemDiv);
            }
            selectContainer[selectContainerIndex].appendChild(fakeOptionsContainerDiv);

            fakeSelectDiv.addEventListener("click", clickHandlerFunction);

            if(selectElement.disabled) {
                // Remove click-handler if select is disabled
                fakeSelectDiv.removeEventListener("click", clickHandlerFunction);
            }
        }
    };
    var deleteAll = function() {
        $(".select-selected").remove();
        $(".select-items").remove();
    };
    myCustomSelect.init = init;
    myCustomSelect.delete = deleteAll;
});

/* When the select box is clicked, close any other select boxes,
and open/close the current select box: */
function clickHandlerFunction(e) {
    e.stopPropagation();
    if(this !== null) {
        closeAllSelect(this);
        if(this.nextSibling !== null) {
            this.nextSibling.classList.toggle("select-hide");
        }
        this.classList.toggle("select-arrow-active");
    }
}

function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
    except the current select box: */
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (i = 0; i < selectContainer.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}



$(document).ready(function(){

    myCustomSelect.init();

    /* If the user clicks anywhere outside the select box,
    then close all select boxes: */
    document.addEventListener("click", closeAllSelect);

} );
